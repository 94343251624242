import { useGetLocale } from 'src/components/global/LocaleProvider';
import { convertDateToLocaleString } from 'src/utils/date';
import { useTranslation } from 'react-i18next';
import { useGetOverdueInvoicesReportingWidget } from 'src/apis/reportingWidgetsAPI';
import { IOverdueInvoicesWidgetData } from 'src/apis/types/overdueInvoicesAPI';
import { useState } from 'react';
import { dataGridCellFontFamilyMonospaceClassName } from 'src/components/mui-components/DataGrid';
import ReportingWidgetWithTable from '../ReportingWidgetWithTable';

const parseRowData = (data: IOverdueInvoicesWidgetData[], locale: string) => {
  const newRow = data.map((item) => ({
    customer: item.customerName,
    project: item.projectName,
    invoiceAmount: item.invoiceAmount.toLocaleString(locale),
    invoiceDate: convertDateToLocaleString(new Date(item.invoiceDate), locale),
    dueDate: convertDateToLocaleString(new Date(item.dueDate), locale),
    daysOverdue: item.daysOverdue.toLocaleString(locale),
  }));
  return newRow;
};

export const OverdueInvoicesReportingWidget = () => {
  const { t } = useTranslation('reportingWidgets');
  const locale = useGetLocale();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 15,
  });
  const { widgetName, widgetData, currencyAbb, paginationInfo, isError, isLoading } =
    useGetOverdueInvoicesReportingWidget(paginationModel);

  const getColumnData = () => [
    {
      headerName: t('TableHeaderProject'),
      key: 'project',
      flex: 3,
    },
    {
      headerName: t('TableHeaderCustomer'),
      key: 'customer',
      flex: 2,
    },
    {
      headerName: `${t('TableHeaderSum')} (${currencyAbb})`,
      key: 'invoiceAmount',
      headerAlign: 'right',
      align: 'right',
      flex: 2.5,
      cellClassName: dataGridCellFontFamilyMonospaceClassName,
    },
    {
      headerName: t('TableHeaderInvoiceDate'),
      key: 'invoiceDate',
      headerAlign: 'center',
      align: 'center',
      flex: 1.5,
    },
    {
      headerName: t('TableHeaderDueDate'),
      key: 'dueDate',
      headerAlign: 'center',
      align: 'center',
      flex: 1.5,
    },
    {
      headerName: t('TableHeaderDaysOverdue'),
      key: 'daysOverdue',
      flex: 1.5,
      headerAlign: 'right',
      align: 'right',
    },
  ];
  return (
    <ReportingWidgetWithTable
      name={widgetName}
      title={t('OverdueInvoices')}
      data={parseRowData(widgetData, locale)}
      infoIconText={t('OverdueInvoicesWidgetDescription')}
      columnData={getColumnData()}
      hasPagination
      paginationModel={paginationModel}
      setPaginationModel={setPaginationModel}
      rowCount={Number(paginationInfo.totalRecord)}
      noDataMessage={t('OverdueInvoicesEmptyMessage')}
      isEmpty={widgetData.length <= 0}
      isError={isError}
      isLoading={isLoading}
    />
  );
};

export default OverdueInvoicesReportingWidget;
